<template>
  <v-container fill-height class="accent3 d-flex justify-start align-start" fluid>
    <v-row class="px-4 py-8" justify="center" align="center">
      <v-card elevation="0" class="ma-2 pa-8 accent--text" width="100%" height="100%" color="accent3">
        <v-card-title class="text-h5 font-weight-bold">Participants & Scores</v-card-title>
        <v-row class="pa-1 mb-4 d-flex justify-space-between" no-gutters>
          <v-col cols="3" class="secondary white--text">
            <v-card-title class="overline">Questions</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold">{{ questionlength }}</v-card-subtitle>
          </v-col>
          <v-col cols="3" class="success white--text">
            <v-card-title class="overline">Participants</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold">{{ participantSessions.length }}</v-card-subtitle>
          </v-col>
          <v-col cols="3" class="accent white--text">
            <v-card-title class="overline">Game Mode</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold">{{ session.type | humanizeSession }}</v-card-subtitle>
          </v-col>
          <v-col cols="12" class="my-1 accent white--text">
            <v-card-title class="overline">Invited Players</v-card-title>
            <v-card-subtitle class="caption font-weight-bold">{{ session.emaillist.join(", ") }}</v-card-subtitle>
          </v-col>
        </v-row>
        <v-simple-table v-if="participantSessionsExists" class="accent3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left accent--text overline">S.No</th>
                <th class="text-left accent--text overline">Name</th>
                <th class="text-left accent--text overline">Score</th>
                <th class="text-left accent--text overline">Correct/Attempted</th>
                <th class="text-left accent--text overline">Accuracy Total</th>
                <th class="text-left accent--text overline">Accuracy Answered</th>
                <th class="text-left accent--text overline">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(participant, index) in participantSessions" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ participant.name }}</td>
                <td>{{ participant.totalscore }}</td>
                <td>{{ correctanswers[index] }}/{{ totalquestions[index] }}</td>
                <td>{{ Math.round((correctanswers[index] / questionlength) * 100) }}%</td>
                <td>{{ Math.round((correctanswers[index] / totalquestions[index]) * 100) }}%</td>
                <td>
                  <v-flex class="text-xs-center sm-and-down">
                    <v-btn
                      text
                      large
                      color="success"
                      :to="{
                        name: 'answer-details',
                        params: { id: participant.participantsessionid },
                      }"
                    >
                      <font-awesome-icon icon="clipboard-list" pull="left"></font-awesome-icon>
                      Answer sheet</v-btn
                    >
                  </v-flex>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn large color="secondary" @click="goBack()" class="mt-4">
          <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
          Back</v-btn
        >
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { getAllParticipantSessions } from "@/api/QuizApi"

export default {
  name: "session-data",
  created() {
    getAllParticipantSessions({ quizid: this.$route.params.quizid, id: this.$route.params.id })
  },

  computed: {
    ...mapState({
      participantSessions: (state) => state.quiz.participantSessions,
      session: (state) => state.quiz.currentSession,
    }),
    participantSessionsExists() {
      return this.participantSessions?.length > 0
    },
    correctanswers() {
      var correctanswers = []
      this.participantSessions?.forEach((e) => {
        var answered = 0
        e?.details?.forEach((i) => {
          if (i?.chosenanswer !== "No Answer") answered += 1
        })
        correctanswers.push(answered)
      })
      return correctanswers
    },
    totalquestions() {
      var totals = []
      this.participantSessions.forEach((e) => {
        totals.push(e.details.length)
      })
      return totals
    },
    questionlength() {
      return this.session?.questions?.length
    },
  },

  methods: {
    ...mapActions(["getParticipantSessions"]),

    goBack() {
      this.$router.go(-1)
    },
  },
  filters: {
    humanizeSession: function (value) {
      const session_type = { S: "Simple", L: "Live", C: "Classic" }
      return session_type[value]
    },
  },
}
</script>
<style lang="scss" scoped></style>
