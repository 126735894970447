var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "accent3 d-flex justify-start align-start",
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "px-4 py-8",
          attrs: { justify: "center", align: "center" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "ma-2 pa-8 accent--text",
              attrs: {
                elevation: "0",
                width: "100%",
                height: "100%",
                color: "accent3"
              }
            },
            [
              _c("v-card-title", { staticClass: "text-h5 font-weight-bold" }, [
                _vm._v("Participants & Scores")
              ]),
              _c(
                "v-row",
                {
                  staticClass: "pa-1 mb-4 d-flex justify-space-between",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "secondary white--text",
                      attrs: { cols: "3" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Questions")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.questionlength))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "success white--text",
                      attrs: { cols: "3" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Participants")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.participantSessions.length))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "accent white--text", attrs: { cols: "3" } },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Game Mode")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm._f("humanizeSession")(_vm.session.type))
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "my-1 accent white--text",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Invited Players")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "caption font-weight-bold" },
                        [_vm._v(_vm._s(_vm.session.emaillist.join(", ")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.participantSessionsExists
                ? _c("v-simple-table", {
                    staticClass: "accent3",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("S.No")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Name")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Score")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Correct/Attempted")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Accuracy Total")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Accuracy Answered")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Actions")]
                                  )
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.participantSessions, function(
                                  participant,
                                  index
                                ) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c("td", [
                                      _vm._v(_vm._s(participant.name))
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(participant.totalscore))
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.correctanswers[index]) +
                                          "/" +
                                          _vm._s(_vm.totalquestions[index])
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          Math.round(
                                            (_vm.correctanswers[index] /
                                              _vm.questionlength) *
                                              100
                                          )
                                        ) + "%"
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          Math.round(
                                            (_vm.correctanswers[index] /
                                              _vm.totalquestions[index]) *
                                              100
                                          )
                                        ) + "%"
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "text-xs-center sm-and-down"
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  large: "",
                                                  color: "success",
                                                  to: {
                                                    name: "answer-details",
                                                    params: {
                                                      id:
                                                        participant.participantsessionid
                                                    }
                                                  }
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: "clipboard-list",
                                                    pull: "left"
                                                  }
                                                }),
                                                _vm._v(" Answer sheet")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      861765696
                    )
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  attrs: { large: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "arrow-left", pull: "left" }
                  }),
                  _vm._v(" Back")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }